import { Vue, Component, Prop } from 'vue-property-decorator';
import * as highcharts from 'highcharts';
import * as mws from '@/shared/MwsViewModels';
import template from './donut-chart.vue';
import createFundDonutChartOptions from './donut-chart-options';

@Component({
  mixins: [template],
})
export default class DonutChart extends Vue {
  @Prop()
  data: mws.DonutChartModel;
  @Prop()
  index: number;

  selectedItemIndex?: number = null;
  readonly regionSecondaryValue: string = '#MERGE#';

  private chart: any;

  get chartData(): mws.SeriesDataDonut[] {
    return this.data.chart.series.data as mws.SeriesDataDonut[];
  }

  get fundTableHeader(): string {
    return this.data.fundTableHeader ? this.data.fundTableHeader : "Fund %";
  }

  mounted() {
    this.configureChart(this.index);
    this.onItemSelect(0);
    this.onItemDeselect(0);
  }

  public isSecondaryNested(item: mws.SeriesDataDonut) {
    return item.secondaryInfo && item.secondaryInfo !== this.regionSecondaryValue;
  } 

  private onItemSelect(rowIndex: number): void {
    this.selectedItemIndex = rowIndex;
    this.clearHoverStateOfDonutPieces();
    if (this.chart.series[0].data[rowIndex]) {
      this.chart.series[0].data[rowIndex].setState('hover');
    }
  }

  private onItemDeselect(rowIndex: number): void {
    if (this.selectedItemIndex === rowIndex) {
      this.selectedItemIndex = null;
    }
    this.clearHoverStateOfDonutPieces();
  }

  private clearHoverStateOfDonutPieces(): void {
    for (let i = 0; i < this.chart.series[0].data.length; ++i) {
      this.chart.series[0].data[i].setState();
    }
  }

  private configureChart(index: number): void {
    let pieDataAbsoluteYs: mws.SeriesDataDonut[] = [];
    this.chartData.forEach(data => {
      pieDataAbsoluteYs.push({...data, y: Math.abs(data.y)});
    });
    const pieColours = pieDataAbsoluteYs.map((element) => element.color);

    const chartOptions = createFundDonutChartOptions(pieColours, pieDataAbsoluteYs, {
      mouseOver: (event: any) => this.onItemSelect(event.target.index),
      mouseOut: (event: any) => this.onItemDeselect(event.target.index),
    });

    this.chart = highcharts.chart(`asset-chart-${index}`, chartOptions);
    return undefined;
  }
}
